<template>
  <div>
    <table
      style="font-size: 11px;"
      id="postuladoTerceros"
      class="table table-bordered table-striped table-hover table-sm team-roster-table table-responsive"
      v-if="$parent.$parent.filtros.tipo_control == 4"
    >
      <thead class="text-center">
        <tr>
          <th></th>
          <th>
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="$parent.filtrosTerceros.cmViaje"
              placeholder="# Viaje"
              @keyup.enter="$parent.$parent.getIndex()"
            />
          </th>
          <th class="p-3">Barriles</th>
          <th>
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="$parent.filtrosTerceros.cmGuia"
              placeholder="N° Guía"
              @keyup.enter="$parent.$parent.getIndex()"
            />
          </th>
          <th>
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="$parent.filtrosTerceros.cmVehiculo"
              placeholder="Vehículo"
              @keyup.enter="$parent.$parent.getIndex()"
            />
          </th>
          <th>
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="$parent.filtrosTerceros.cmRemolque"
              placeholder="Remolque"
              @keyup.enter="$parent.$parent.getIndex()"
            />
          </th>
          <th>
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="$parent.filtrosTerceros.cmCliente"
              placeholder="Cliente"
              @keyup.enter="$parent.$parent.getIndex()"
            />
          </th>
          <th>
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="$parent.filtrosTerceros.cmTransportadora"
              placeholder="Transportadora"
              @keyup.enter="$parent.$parent.getIndex()"
            />
          </th>
          <th>
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="$parent.filtrosTerceros.cmConductor"
              placeholder="Conductor"
              @keyup.enter="$parent.$parent.getIndex()"
            />
          </th>
          <th>
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="$parent.filtrosTerceros.cmSitio"
              placeholder="Sitio"
              @keyup.enter="$parent.$parent.getIndex()"
            />
          </th>
          <th>
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="$parent.filtrosTerceros.cmSitioDestino"
              placeholder="Sitio destino"
              @keyup.enter="$parent.$parent.getIndex()"
            />
          </th>
          <th>
            <input
              type="date"
              v-b-popover.hover.left="'Fecha de cargue'"
              class="form-control form-control-sm"
              v-model="$parent.filtrosTerceros.cmFechaCargue"
              placeholder="Fecha Cargue"
              @change="$parent.$parent.getIndex()"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="postulacion in postuladoTerceros.data" :key="postulacion.id">
          <td>
            <!-- Split dropright button -->
            <div
              class="btn-group"
              :class="
                postuladoTerceros.data.length == 1 ? 'dropop' : 'dropright'
              "
              style="position: static; z-index: auth"
            >
              <button
                type="button"
                class="btn btn-dark btn-sm"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-align-justify"></i>
              </button>
              <div class="dropdown-menu">
                <a
                  class="dropdown-item"
                  data-toggle="modal"
                  data-target="#modal_calc_ventas_terceros"
                  style="cursor: pointer"
                  @click="llenarModalCalculatorVentasTerceros(postulacion)"
                  v-if="
                    $store.getters.can(
                      'hidrocarburos.ventanasOperaciones.calculoVentasTerceros'
                    ) &&
                      (!postulacion.det_guia_regalia_tercero ||
                        postulacion.det_guia_regalia_tercero.guia_id == null)
                  "
                >
                  <i class="fas fa-calculator"></i>
                  Calculo API
                </a>
                <a
                  class="dropdown-item"
                  data-toggle="modal"
                  data-target="#"
                  style="cursor: pointer"
                  v-if="
                    $store.getters.can(
                      'hidrocarburos.ventanasOperaciones.diligenciarGuiaVentasTerceros'
                    ) &&
                      postulacion.det_guia_regalia_tercero &&
                      postulacion.det_guia_regalia_tercero.guia_id == null
                  "
                  @click="
                    diligenciarGuia(
                      postulacion.det_guia_regalia_tercero,
                      postulacion.id
                    )
                  "
                >
                  <i class="fas fa-clipboard-list"></i>
                  Diligenciamiento Guía
                </a>
                <a
                  class="dropdown-item"
                  style="cursor: pointer"
                  v-if="
                    postulacion.det_guia_regalia_tercero &&
                      postulacion.det_guia_regalia_tercero.guia_id !== null &&
                      $store.getters.can(
                        'hidrocarburos.ventanasOperaciones.actualizarGuiaVentasTerceros'
                      )
                  "
                  @click="editGuia(postulacion.det_guia_regalia_tercero)"
                >
                  <i class="fas fa-marker"></i>
                  Actualizar Guía
                </a>
                <a
                  id="botonImp"
                  type="button"
                  class="dropdown-item"
                  style="cursor: pointer"
                  @click="printGuia(postulacion.det_guia_regalia_tercero.id)"
                  v-if="
                    $store.getters.can(
                      'hidrocarburos.ventanasOperaciones.printGuiaVentasTerceros'
                    ) &&
                      postulacion.det_guia_regalia_tercero &&
                      postulacion.det_guia_regalia_tercero.guia_id !== null &&
                      postulacion.det_guia_regalia_tercero.guia_id !== undefined
                  "
                >
                  <i class="fas fa-print"></i>
                  Imprimir Guía
                </a>
                <a
                  class="dropdown-item"
                  style="cursor: pointer"
                  v-if="
                    $store.getters.can(
                      'hidrocarburos.ventanasOperaciones.cierreManualGuiaVentasTerceros'
                    ) &&
                      postulacion.det_guia_regalia_tercero != null &&
                      postulacion.det_guia_regalia_tercero.guia_id != null
                  "
                  @click="cierreManual(postulacion.det_guia_regalia_tercero_id)"
                >
                  <i class="fas fa-lock"></i>
                  Cierre Manual
                </a>
                <a
                  id="botonAnu"
                  type="button"
                  class="dropdown-item"
                  style="cursor: pointer"
                  @click="
                    anularCambioGuia(postulacion.det_guia_regalia_tercero)
                  "
                  v-if="
                    $store.getters.can(
                      'hidrocarburos.ventanasOperaciones.anularGuiaVentasTerceros'
                    ) &&
                      postulacion.det_guia_regalia_tercero &&
                      postulacion.det_guia_regalia_tercero.guia_id !== null &&
                      postulacion.det_guia_regalia_tercero.guia_id !== undefined
                  "
                >
                  <i class="fas fa-ban"></i>
                  Anular Guia - Cambio
                </a>
              </div>
            </div>
          </td>
          <!-- Viaje -->
          <td
            class="text-center"
            v-if="postulacion.det_guia_regalia_tercero_id"
          >
            <button
              type="button"
              class="btn btn-sm btn-default py-0"
              data-toggle="modal"
              data-target="#modal-form-detViaje"
              @click="
                llenarModalDetViaje(postulacion.det_guia_regalia_tercero_id)
              "
            >
              {{ postulacion.det_guia_regalia_tercero_id }}
            </button>
          </td>
          <td class="text-center" v-else>
            <span class="badge bg-danger">Sin Asignar</span>
          </td>
          <!-- Barriles Calculados -->
          <td
            class="text-center"
            v-if="
              postulacion.det_guia_regalia_tercero_id &&
                postulacion.det_guia_regalia_tercero.volumen_calculado
            "
          >
            <span class="badge bg-success">{{
              postulacion.det_guia_regalia_tercero.volumen_calculado
            }}</span>
          </td>
          <td class="text-center" v-else>
            <span class="badge bg-danger">Sin Calcular</span>
          </td>
          <!-- Número Guía -->
          <td
            class="text-center"
            v-if="
              postulacion.det_guia_regalia_tercero_id &&
                postulacion.det_guia_regalia_tercero.guia_id
            "
          >
            <span class="badge bg-info">{{
              postulacion.det_guia_regalia_tercero.numero_guia
            }}</span>
          </td>
          <td class="text-center" v-else>
            <span class="badge bg-danger">Sin Asignar</span>
          </td>
          <!-- Vehiculo -->
          <td colspan="1" v-if="postulacion.vehiculo != null">
            {{ postulacion.vehiculo }}
          </td>
          <td v-else><span class="badge badge-danger"> Sin Asignar </span></td>
          <!-- Remolque -->
          <td v-if="postulacion.remolque != null">
            {{ postulacion.remolque }}
          </td>
          <td v-else><span class="badge badge-danger"> Sin Asignar </span></td>
          <!-- Cliente -->
          <td>
            {{ postulacion.det_oferta.oferta.cliente.razon_social }}
          </td>
          <!-- Transportadora -->
          <td>
            <div v-if="postulacion.transportadora != null">
              {{ postulacion.transportadora.razon_social }}
            </div>
          </td>
          <!-- Conductor -->
          <td>
            <span class="badge badge-info">
              {{ postulacion.conductor }}
            </span>
          </td>
          <td>
            {{ postulacion.det_oferta.oferta.subasta_producto.sitio.nombre }}
          </td>
          <!-- Sitio Destino -->
          <td v-if="postulacion.sitio_destino != null" class="text-nowrap">
            {{ postulacion.sitio_destino }}
          </td>
          <td v-else><span class="badge badge-danger"> Sin Asignar </span></td>
          <!-- Hora de Cargue -->
          <td class="text-center">
            <span class="badge badge-secondary">
              {{ postulacion.fecha_cargue }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="card-footer">
      <div class="float-left" v-if="postuladoTerceros.total">
        <p>
          Mostrando del <b>{{ postuladoTerceros.from }}</b> al
          <b>{{ postuladoTerceros.to }}</b> de un total:
          <b>{{ postuladoTerceros.total }}</b> Registros
        </p>
      </div>
      <div class="float-left" v-else>
        <p>
          <span class="badge badge-dark">No hay registros para mostrar</span>
        </p>
      </div>
      <pagination
        :data="postuladoTerceros"
        @pagination-change-page="$parent.$parent.getIndex"
        :limit="5"
        align="right"
      ></pagination>
    </div>
    <!-- Modal Calcular Crudo - Ventas Terceros -->
    <div
      class="modal fade"
      id="modal_calc_ventas_terceros"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Calcular volumenes de ventas terceros
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal3"
              @click="limpiarModalCalculatorVentasTerceros()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- Cuerpo del modal -->
          <div class="modal-body">
            <CalculatorVentasTerceros
              v-if="modalCalcVentasTerceros"
              ref="CalculatorVentasTerceros"
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm btn-success"
              @click="guardarCalculo()"
            >
              <i class="fas fa-check-circle"></i>Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pagination from "laravel-vue-pagination";
import axios from "axios";
import moment from "moment";
import CalculatorVentasTerceros from "../../../../components/CalculatorVentasTerceros";
import { VBPopover } from "bootstrap-vue";

export default {
  name: "VentanaOperacionesPostuladosTerceros",
  components: {
    pagination,
    CalculatorVentasTerceros,
  },
  directives: {
    "b-popover": VBPopover,
  },
  data() {
    return {
      postuladoTerceros: {},
      postulacion: {},
      sitio_type: null,
      sitio_id: null,
      modalCalcVentasTerceros: false,
      volumen_calculado: 0,
      peso_vehiculo: 0,
      peso_remolque: 0,
    };
  },

  methods: {
    llenarModalCalculatorVentasTerceros(postulacion) {
      this.modalCalcVentasTerceros = true;
      this.postulacion = postulacion;
    },

    limpiarModalCalculatorVentasTerceros() {
      this.modalCalcVentasTerceros = false;
      this.volumen_calculado = 0;
    },

    diligenciarGuia(detGuiaRegTerceros, postulacion_id) {
      return this.$router.push({
        name: "/Hidrocarburos/DetGuiasVentasTercerosForm",
        params: {
          accion: "Diligenciar",
          actoGuia: 2,
          data_edit: detGuiaRegTerceros,
          postulacion_id: postulacion_id,
          sitio_type: this.$parent.$parent.filtros.sitio_type,
          sitio_id: this.$parent.$parent.filtros.sitio_id,
          sitio_type2: this.$parent.$parent.filtros.sitio_type2,
          tipo_control: this.$parent.$parent.filtros.tipo_control,
        },
      });
    },

    printGuia(idViaje) {
      window.open(
        process.env.VUE_APP_API_URL + "/toPrint/guiaVentasTerceros/" + idViaje,
        "_blank"
      );
    },

    async guardarCalculo() {
      this.volumen_calculado = await this.$refs.CalculatorVentasTerceros
        .max_barriles;
      let api = await this.$refs.CalculatorVentasTerceros.API;

      this.$swal({
        icon: "warning",
        title: `Está seguro de guardar el calculo?`,
        showCancelButton: true,
        confirmButtonText: "Si, Guardar!",
      }).then((result) => {
        if (result.value) {
          this.$parent.$parent.cargando = true;
          const me = this;
          axios
            .put(
              "/api/hidrocarburos/ventanasEnturnamientoOperacion/guardarVolumenesVentasTerceros",
              {
                postulacion: this.postulacion,
                api: api,
                volumen_calculado: this.volumen_calculado,
                sitio_type: this.$parent.$parent.filtros.sitio_type2,
                sitio_id: this.$parent.$parent.filtros.sitio_id,
              }
            )
            .then((response) => {
              me.$parent.$parent.cargando = false;
              me.$parent.$parent.getIndex();
              this.$refs.closeModal3.click();
              me.$swal({
                icon: "success",
                title: response.data.msg,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch(function(error) {
              me.$parent.$parent.cargando = false;
              me.$swal({
                icon: "error",
                title: "Ocurrió un error: " + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    editGuia(detGuiaRegTerceros) {
      return this.$router.push({
        name: "/Hidrocarburos/DetGuiasVentasTercerosForm",
        params: {
          accion: "Actualizar",
          actoGuia: 4,
          data_edit: detGuiaRegTerceros,
          sitio_type: this.$parent.$parent.filtros.sitio_type,
          sitio_id: this.$parent.$parent.filtros.sitio_id,
        },
      });
    },

    cierreManual(viajeTerceroId) {
      this.$swal({
        icon: "warning",
        title: `Está seguro de finalizar el viaje?`,
        showCancelButton: true,
        confirmButtonText: "Si, Guardar!",
      }).then((result) => {
        if (result.value) {
          this.$parent.$parent.cargando = true;
          const me = this;
          axios({
            method: "PUT",
            url: "/api/hidrocarburos/detalleGuiasVentasTerceros/cierreManual",
            data: {
              viaje_tercero_id: viajeTerceroId,
            },
          })
            .then((response) => {
              me.$parent.$parent.cargando = false;
              me.$parent.$parent.getIndex();
              this.$refs.closeModal3.click();
              me.$swal({
                icon: "success",
                title: "Se realizó el cambio de estado exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch(function(error) {
              me.$parent.$parent.cargando = false;
              me.$swal({
                icon: "error",
                title: "Ocurrió un error: " + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    anularCambioGuia(detGuiaRegTerceros) {
      return this.$router.push({
        name: "/Hidrocarburos/DetGuiasVentasTercerosForm",
        params: {
          accion: "Cambiar",
          actoGuia: 3,
          data_edit: detGuiaRegTerceros,
          sitio_type: this.$parent.$parent.filtros.sitio_type,
          sitio_id: this.$parent.$parent.filtros.sitio_id,
          sitio_type2: this.$parent.$parent.filtros.sitio_type2,
          tipo_control: this.$parent.$parent.filtros.tipo_control,
        },
      });
    },

    llenarModalDetViaje(det_guia_regalia_tercero) {
      this.$parent.$parent.$refs.ViajesDetViaje.llenar_modal_detViaje(
        det_guia_regalia_tercero,
        2
      );
    },
  },
  mounted() {},
};
</script>
